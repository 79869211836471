import { ListItemButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import colorConfigs from '../../configs/colorConfigs'
import sizeConfigs from '../../configs/sizeConfigs'
import { selectCurrentPath } from '../../redux/features/appStateSlice'
import { RouteType } from '../../routes/config'

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const currentPath = useSelector(selectCurrentPath)
  const { t } = useTranslation()

  return (
    item.sidebarProps && item.path ? (
      <ListItemButton
        component={Link}
        to={item.path}
        sx={{
          '&: hover': {
            backgroundColor: colorConfigs.sidebar.hoverBg
          },
          fontSize: '1.2vw',
          backgroundColor: currentPath === item.state ? colorConfigs.sidebar.activeBg : 'unset',
          paddingY: `${sizeConfigs.sidebar.paddingRaw / 2}px`,
          paddingX: `1vw`,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-start' }}>
          {item.sidebarProps.icon}
          <Typography sx={{ fontSize: item.sidebarProps.icon ? '1.3vw' : '1.2vw', marginLeft: item.sidebarProps.icon ? '0.5vw' : '2.8vw' }}>
            {t(item.sidebarProps.textPath)}
          </Typography>
        </div>
      </ListItemButton>
    ) : null
  );
};

export default SidebarItem;