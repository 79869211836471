// Utils
import { PendingRequestModel, PendingRequestPayload } from '../types/requests'
import { mapRowModel } from './product-utils'

export const mapRequestModel = (request: PendingRequestPayload): PendingRequestModel => ({
    id: request.id,
    type: request.type,
    objectId: request.objectId,
    info: request.info,
    object: request?.object && mapRowModel(request.object)
})