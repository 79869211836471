// Dependencies
import { DateValidationError } from '@mui/x-date-pickers'
import { Moment } from 'moment'

// Types
import { ManageOrdersActionsTypes } from './types'
import {
    FilterOrdersSwitchesState,
    OrderModel,
    OrdersDeclineTranslatedOptions,
    OrderStatusTypes,
    UpdateOrderInput
} from '../../../types/orders'

export function updateOrderStatus(
    payload: UpdateOrderInput
) {
    return {
        type: ManageOrdersActionsTypes.UPDATE_STATUS,
        payload,
    }
}

export function updateOrderStatusSuccess(
    payload: {
        orders: OrderModel[],
        snackbar: any,
    }
) {
    return {
        type: ManageOrdersActionsTypes.UPDATE_STATUS_SUCCESS,
        payload,
    }
}

export function updateOrderStatusError(
    payload: any,
) {
    return {
        type: ManageOrdersActionsTypes.UPDATE_STATUS_ERROR,
        payload,
    }
}

export function getAllOrders(
    payload: {
        useLoading?: boolean
        dateFrom: Moment | null,
        dateTo: Moment | null,
    }
) {
    return {
        type: ManageOrdersActionsTypes.GET_ALL,
        payload,
    }
}

export function getAllOrdersSuccess(
    payload: OrderModel[]
) {
    return {
        type: ManageOrdersActionsTypes.GET_ALL_SUCCESS,
        payload,
    }
}

export function getAllOrdersError(
    payload: any
) {
    return {
        type: ManageOrdersActionsTypes.GET_ALL_ERROR,
        payload,
    }
}

export function selectOrder(
    payload: OrderModel | null
) {
    return {
        type: ManageOrdersActionsTypes.SELECT_ORDER,
        payload,
    }
}

export function filterOrders(
    payload: {
        dateFrom: null | Moment,
        dateTo: null | Moment,
        dateError: DateValidationError | undefined,
        orders: OrderModel[],
    }
) {
    return {
        type: ManageOrdersActionsTypes.FILTER_ORDERS,
        payload,
    }
}

export function clearFeedbackMessage() {
    return {
        type: ManageOrdersActionsTypes.CLEAR_FEEDBACK_MESSAGE,
    }
}

export function setOrderDeclinementReason(
    payload: OrdersDeclineTranslatedOptions | ''
) {
    return {
        type: ManageOrdersActionsTypes.ORDER_DECLINEMENT_REASON,
        payload,
    }
}

export function selectMultipleOrders(
    payload: OrderModel | null
) {
    return {
        type: ManageOrdersActionsTypes.SELECT_MULTIPLE_ORDERS,
        payload,
    }
}

export function handleSwitches(
    payload: FilterOrdersSwitchesState | null
) {
    return {
        type: ManageOrdersActionsTypes.HANDLE_SWITCHES,
        payload,
    }
}

export function updateMultipleStatus(
    payload: {
        status: OrderStatusTypes,
    }
) {
    return {
        type: ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS,
        payload,
    }
}

export function updateMultipleStatusSuccess(
    payload: {
        orders: OrderModel[],
        snackbar: any,
    }
) {
    return {
        type: ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS_SUCCESS,
        payload,
    }
}

export function updateMultipleStatusError(
    payload: any,
) {
    return {
        type: ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS_ERROR,
        payload,
    }
}

export function print(
    payload: {
        orderId: number,
        params: { ownerId?: string, parcelsCount?: number } | null,
    }
) {
    return {
        type: ManageOrdersActionsTypes.PRINT,
        payload,
    }
}

export function printSuccess() {
    return {
        type: ManageOrdersActionsTypes.PRINT_SUCCESS,
    }
}

export function printError(
    payload: any,
) {
    return {
        type: ManageOrdersActionsTypes.PRINT_ERROR,
        payload,
    }
}