// Dependencies
import React, { ReactNode } from 'react'
import { Box, Grid, Typography } from '@mui/material'

type SectionProps = {
    children?: ReactNode
    title?: string
}

const Section = ({ children, title }: SectionProps) => {
    return (
        <Grid
            sx={{
                backgroundColor: 'white',
                width: '100%',
                padding: '1vw',
                borderRadius: '0.8vw',
                paddingTop: '1.5vh',
                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                marginBottom: '2.5vh',
            }}
        >
            {!!title && (
                <Box sx={{ marginBottom: '1.5vh'}}>
                    <Typography sx={{ fontSize: '1.6vw', fontWeight: 'bold' }}>{title}</Typography>
                </Box>
            )}
            {children}
        </Grid>
    )
}

export default Section