// Dependencies
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import { Box, Toolbar, Autocomplete, TextField, AutocompleteRenderInputParams, CircularProgress } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Utils
import colorConfigs from '../../configs/colorConfigs'
import { findUsers, setOwnerUser } from '../../redux/features/userStateSlice'
import { UserLocation } from '../../types/user'
import { debounce } from '../../utils/common-utils'
import { getOwnerUser } from '../../utils/storage-utils'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-input': {
        color: 'white',
        height: 0,
    },
    '& .MuiInputBase-root': {
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        fontSize: '1.3vw'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: 'white',
    },
}))

const AdminToolbar = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const ownerUser = getOwnerUser()

    const [options, setOptions] = React.useState<readonly UserLocation[]>([])
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(false)
    const [value, setValue] = React.useState<UserLocation | null>(null)
    const [inputValue, setInputValue] = React.useState<string>(ownerUser?.name || '');

    const handleSearch = React.useCallback(
        debounce(async (val: string) => {
            setLoading(true)
            const data = await findUsers(val)
            setOptions(data)
            setLoading(false)
        }, 500), []
    )

    return (
        <Box sx={{ flexGrow: 1, marginTop: 8, marginBottom: 2 }}>
            <AppBar position="static" sx={{ backgroundColor: colorConfigs.sidebar.bg, borderRadius: 2 }}>
                <Toolbar>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon style={{ fontSize: '1.6vw' }} />
                        </SearchIconWrapper>
                        <StyledAutoComplete
                            key="admin-search"
                            freeSolo
                            fullWidth
                            loading={loading}
                            filterOptions={(x) => x}
                            loadingText={`${t('searchbar.loading')}...`}
                            noOptionsText={`${t('searchbar.noResults')}...`}
                            id="search-input"
                            open={open}
                            value={value}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue)
                                handleSearch(newInputValue)
                                if (!newInputValue) {
                                    dispatch(setOwnerUser(null))
                                    setValue(null)
                                }
                            }}
                            inputValue={inputValue}
                            onFocus={() => setOpen(true)}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            onChange={(event, newValue) => {
                                dispatch(setOwnerUser(newValue as UserLocation))
                                setValue(newValue as UserLocation)
                            }} 
                            // @ts-ignore
                            getOptionLabel={(option: UserLocation) => option?.name || ''}
                            // @ts-ignore
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            options={options}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextField
                                    {...params}
                                    placeholder="Search…"
                                    // onChange={(event) => handleSearch(event.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress style={{ marginRight: 10 }} color="primary" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Search>
                    {/* <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="undo" color="inherit">
                            <ReplayIcon />
                        </IconButton>
                    </Box> */}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default AdminToolbar