// Dependencies
import { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'

// Utils
import { setCurrentPath } from '../../redux/features/appStateSlice'
import { RouteStates } from '../../routes/config';

type Props = {
  state?: RouteStates;
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.state) {
      dispatch(setCurrentPath(props.state));
    }
  }, [dispatch, props]);

  return (
    <>{props.children}</>
  );
};

export default PageWrapper;