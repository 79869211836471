// Dependencies
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

// Components
import MainLayout from './components/layout/MainLayout'

// Utils
import { routes } from './routes'
import { store } from './redux/store'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={<MainLayout />}
          >
            {routes}
          </Route>
          <Route
              path="*"
              element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
