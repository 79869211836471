export default {
    translations: {
        general: {
            apiError: "Anforderungsfehler, bitte versuchen Sie es erneut...",
        },
        sidebar: {
            products: {
                manage: "Ihre Produkte Verwalten",
                add: "Ein Produkt Hinzufügen"
            },
            orders: {
                manage: "Ihre Bestellungen Verwalten",
            },
            requests: {
                main: "Requests",
                "delete-product": "Delete Product",
                "change-price": "Change Price",
            },
            buttons: {
                logout: {
                    label: 'Ausloggen',
                },
            },
        },
        topbar: {
            selector: {
                label: "Sprache"
            },
        },
        languages: {
            english: "Englisch",
            german: "Deutsch",
            french: "Französisch"
        },
        orders: {
            modal: {
                button: {
                    label: "Abschicken"
                },
                title: "Grund zur Ablehnung",
                title2: "Rejection reason",
                options: {
                    0: "Produkt aus dem Vorrat",
                    1: "Arbeitskräftemangel",
                    2: "Verpackungsmaterialmangel",
                },
            },
            status: {
                new: "neues",
                pending: "ausstehende",
                packaged: "verpackte",
                completed: "gesendete",
                declined: "abgelehnte"
            },
            feedback: {
                statusUpdate: {
                    success: "Auftrag erfolgreich aktualisiert",
                    error: "Fehler beim Aktualisieren des Auftragsstatus"
                }
            },
            cards: {
                orderId: "Auftragsnummer",
                date: "Datum",
                price: "Kosten",
                amount: "Menge",
                product: "Produkt",
                type: "Typ",
                totalAmount: "Total Quantity",
                buttons: {
                    reject: "zurückweisen",
                    accept: "akzeptieren",
                    ready: "bereit",
                    complete: "komplettauftrag",
                    pending: "ausstehende",
                    packaged: "verpackte",
                    moveTo: "anziehen",
                    print: "print",
                }
            },
            configurations: {
                switches: {
                    date: {
                        label: "Datum zeigen",
                    },
                    amount: {
                        label: "Gesamtmenge zeigen",
                    },
                    status: {
                        label: "Show lehnte Aufträge ab",
                    },
                    priority: {
                        label: "Show priority orders",
                    }
                },
                filters: {
                    date: {
                        label: "Filter nach Datum",
                        from: "Datum von",
                        to: "Datum bis",
                    },
                },
            },
            buttons: {
                exportCsv: "Export to csv",
                moveAllToCompleted: "Move all to completed",
            }
            
        },
        products: {
            add: {
                sections: {
                    general: {
                        title: "Allgemeine Informationen",
                        inputs: {
                            first: {
                                label:"Produktname",
                                placeholder: "",
                            },
                            second: {
                                label: "Beschreibung",
                            },
                        },
                    },
                    media: {
                        title: "Medien",
                        buttons: {
                            first: "Neu hochladen",
                        },
                        description: {
                            first: "Only upload images up to 3MB",
                            second: "Supported formats: .png or .jpg",
                        },
                    },
                    pricing: {
                        title: "Preisgestaltung",
                        inputs: {
                            first: {
                                label: "Preis im Laden",
                            },
                        },
                    },
                    metafields: {
                        title: "Produkteigenschaft",
                        fields: {
                            exclusiveToRoasterCup: {
                                title: "Exklusiv bei Roastercup?",
                                options: {
                                    0: "Ja",
                                    1: "Nein",
                                },
                            },
                            typeOfProductListing: {
                                title: "Art der Produktliste",
                                options: {
                                    0: "Ganze Bohnenkaffee",
                                    1: "Gemahlenen Kaffee",
                                    2: "Tee",
                                    3: "Zubehör",
                                    4: "Ausrüstung",
                                },
                            },
                            netWeight: {
                                title: "Nettogewicht",
                                placeholder: "000 Gramm",
                            },
                            roasterCountry: {
                                title: "Roaster Land",
                                options: {
                                    0: "Belgien",
                                    1: "Frankreich",
                                    2: "Deutschland",
                                    3: "Luxemburg",
                                },
                            },
                            decafYesNo: {
                                title: "Decaf?",
                                options: {
                                    0: "Ja Decaf",
                                    1: "Nein",
                                },
                            },
                            coffeeFlavor: {
                                title: "Kaffeegeschmack",
                                options: {
                                    0: "Fruchtig",
                                    1: "Blumen",
                                    2: "Grün / Vegetativ",
                                    3: "Nutty / Kakao",
                                    4: "Geröstet",
                                    5: "Süß",
                                    6: "Sauer / Fermentiert",
                                    7: "Gewürze",
                                },
                            },
                            roastType: {
                                title: "Bratentyp",
                                options: {
                                    0: "Leichter Braten",
                                    1: "Mittlerer Braten",
                                    2: "Dunkler Braten",
                                },
                            },
                            singleOrigin: {
                                title: "Einzelner Ursprung",
                                options: {
                                    0: "Einzelner Ursprung aus Brasilien",
                                    1: "Einzelner Ursprung aus Colombia",
                                    2: "Einzelner Ursprung aus Äthiopien",
                                    3: "Einzelner Ursprung aus Indien",
                                    4: "Einzelner Ursprung aus Indonesien",
                                    5: "Einzelner Ursprung aus Peru",
                                    6: "Einzelner Ursprung aus Uganda",
                                    7: "Einzelner Ursprung aus Vietnam",
                                    8: "Kaffee mischen - mehrere Ursprünge",
                                    9: "Einzelner Ursprung - Anderes Land",
                                },
                            },
                            beanType: {
                                title: "Bohnentyp",
                                options: {
                                    0: "Arabica",
                                    1: "Robusta",
                                    2: "Mischung aus Arabica und Robusta",
                                    3: "Mix - etwas Arabica oder Robusta enthalten",
                                },
                            },
                            accessories: {
                                title: "Zubehör",
                                options: {
                                    0: "Mahlen",
                                    1: "Gläser",
                                    2: "Platten",
                                    3: "Teetöpfe",
                                    4: "Sonstiges Zubehör",
                                },
                            },
                            equipment: {
                                title: "Ausrüstung",
                                options: {
                                    0: "Kaffee Tropfmaschine",
                                    1: "Espresso Automatische Maschine",
                                    2: "Chemex",
                                    3: "Französische Presse",
                                    4: "AeroPress",
                                    5: "Andere manuelle Extraktionsgeräte",
                                },
                            },
                            organic: {
                                title: "Organisch (Bio)",
                                options: {
                                    0: "Ja",
                                    1: "Nein",
                                },
                            },
                            fairtrade: {
                                title: "Fairtrade",
                                options: {
                                    0: "Ja",
                                    1: "Nein",
                                },
                            },
                            grind: {
                                title: "Schleifen",
                                options: {
                                    0: "Grob (ideal für die französische Presse)",
                                    1: "Medium (ideal für Kaffeemaschinen)",
                                    2: "Fein (ideal für Aeropress)",
                                },
                            },
                            netWeightVariant: {
                                title: "Nettogewicht",
                                placeholder: "000 gramm",
                            },
                            priceVariant: {
                                title: "Preis im Laden",
                                placeholder: "€ 0.00",
                            }
                        },
                        menu: {
                            product: "Produkte",
                            variant: "Variante",
                        },
                    },
                },
                buttons: {
                    save: "Speichern",
                    showAll: "Zeige Alles",
                    hide: "Verstecken",
                },
                feedback: {
                    save: {
                        success: "Produkt mit Erfolg hinzugefügt!",
                        error: "Fehler beim Hinzufügen von Produkten, bitte versuchen Sie es erneut!",
                    },
                },
            },
            manage: {
                modal: {
                    button: {
                        label: "Abschicken"
                    },
                    title: "Grund für die Löschung",
                    options: {
                        0: "Produkt eingestellt",
                        1: "Vorübergehend entfernt",
                        2: "Anderer Grund (Text hinzufügen)",
                    },
                },
                feedback: {
                    save: {
                        success: "Produkt erfolgreich gespeichert",
                        error: "Fehler beim Speichern des Produkts"
                
                    },
                    remove: {
                        success: "Produkt erfolgreich entfernt",
                        error: "Fehler beim Entfernen des Produkts"
                    }
                },
                table: {
                    header: {
                        product: "Produkt",
                        inventory: "Inventar",
                        category: "Produktkategorie",
                        price: "Ladenverkaufspreis",
                        type: "Type",
                        actions: {
                            remove: "Entfernen"
                        }
                    },
                    column: {
                        inventory: {
                            adjunct: "auf Lager"
                        },
                        category: {
                            coffee: "kaffee"
                        },
                    },
                },
            },
        },
        requests: {
            noResults: "No requests found...",
            feedback: {
                update: {
                    success: "Request updated with success",
                    error: "Error while updating the request",
                },
            },
            cards: {
                buttons: {
                    approve: {
                        title: "Approve",
                    },
                    reject: {
                        title: "Reject",
                    },
                },
                product: "Product",
                roaster: "Roaster",
                reason: "Reason",
                requestedPrice: "Requested Price",
                currentPrice: "Current Price",
            },
        },
    },
}