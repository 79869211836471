export const debounce = (func: any, wait: number) => {
    let timeout: any
      
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    }
}

export const sleep = (duration: number): Promise<void> => 
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });