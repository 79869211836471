// Utils
import { File, Files } from './files'
import { PendingRequests } from './requests'

export type ProductModelPrice = {
    main: string
    requested?: string
}

export type SelectFieldTypes = 'price' | 'inventory' | 'action'

export interface ProductModel {
    product: string
    inventory: number | string
    id: number
    category: any
    price: ProductModelPrice
    preDiscountPrice: string
    img: File;
    selectedField?: SelectFieldTypes
    pendingDeletion: boolean
    roaster?: string
    variantId: VariantId
    weight?: string
    type?: string,
    shouldUpdateMultiple?: boolean,
    variant_ids?: IProductVariantId[]
}

export type ManageProductsError = {
    alert?: string;
    message?: string;
};

export type ProductImage = {
    created_at: string,
    id: number,
    position: number,
    product_id: number,
    variant_ids: number[],
    src: string,
    width: number,
    height: number,
    updated_at: string,
    alt: string
}

export type ProductOptions = {
    id: number,
    name: string,
    position: number,
    product_id: number,
    values: string[],
}

export type InventoryPolicyTypes = 'deny' | 'continue'

export type InventoryManagementTypes = "shopify"

export type ProductModelPriceData = {
    amount: number,
    currency_code: string,
}

export type PresentmentPrices = {
    price: ProductModelPriceData,
    compare_at_price: ProductModelPriceData
}

export type ProductWeightsTypes = 'g' | 'kg'

export type VariantId = number

export type ProductVariants = {
    barcode?: string,
    compare_at_price?: string,
    created_at?: string,
    fulfillment_service?: string,
    grams?: number | string,
    id: VariantId,
    image_id?: number,
    inventory_item_id?: number,
    inventory_management?: InventoryManagementTypes,
    inventory_policy?: InventoryPolicyTypes,
    inventory_quantity?: number,
    old_inventory_quantity?: number,
    option1?: string,
    option2?: string,
    option3?: string,
    presentment_prices?: PresentmentPrices[],
    position?: number,
    price?: string,
    product_id: number,
    requires_shipping?: boolean,
    sku?: string,
    taxable?: boolean,
    tax_code?: string,
    title?: string,
    updated_at?: string,
    weight?: number,
    weight_unit?: ProductWeightsTypes,
}

export type ProductStatusTypes = 'active'

export type MetafieldsKeys = 
    | "type_of_product_listing"
    | "accessories"
    | "equipment_accessories"
    | "bio"
    | "fairtrade"
    | "exclusive_to_roastercup_"
    | "net_weight"
    | "roaster_country"
    | "decaf_yes_no"
    | "coffee_flavor"
    | "roast_type"
    | "single_origin"
    | "bean_type"
    | "grind"
    | "process"
    | "weight"
    | "prive_variant"

export enum MetafieldKeysEnum {
    typeOfProductListing = "type_of_product_listing",
    accessories = "accessories",
    equipment = "equipment_accessories",
    organic = "bio",
    fairtrade = "fairtrade",
    exclusiveToRoasterCup = "exclusive_to_roastercup_",
    netWeight = "net_weight",
    roasterCountry = "roaster_country",
    decafYesNo = "decaf_yes_no",
    coffeeFlavor = "coffee_flavor",
    roastType = "roast_type",
    singleOrigin = "single_origin",
    beanType = "bean_type",
    grind = "grind",
    process = "process",
    netWeightVariant = "weight",
    priceVariant = "prive_variant",
}

export type MetafieldsValueType =
    | "list.single_line_text_field"
    | "boolean"
    | "number_integer"
    | "single_line_text_field"
    | "number_decimal"

export enum MetafieldsValueTypeEnum {
    typeOfProductListing = "single_line_text_field",
    accessories = "list.single_line_text_field",
    equipment = "single_line_text_field",
    organic = "boolean",
    fairtrade = "boolean",
    exclusiveToRoasterCup = "list.single_line_text_field",
    netWeight = "number_integer",
    roasterCountry = "list.single_line_text_field",
    decafYesNo = "single_line_text_field",
    coffeeFlavor = "list.single_line_text_field",
    roastType = "list.single_line_text_field",
    singleOrigin = "list.single_line_text_field",
    beanType = "list.single_line_text_field",
    grind = "list.single_line_text_field",
    process = "single_line_text_field",
    netWeightVariant = "number_integer",
    priceVariant = "number_decimal",
}

export type MetafieldsNamespaces =
    | "custom"

export enum MetafieldsNamespacesEnum {
    typeOfProductListing = "custom",
    accessories = "custom",
    equipment = "custom",
    organic = "custom",
    fairtrade = "custom",
    exclusiveToRoasterCup = "custom",
    netWeight = "custom",
    roasterCountry = "custom",
    decafYesNo = "custom",
    coffeeFlavor = "custom",
    roastType = "custom",
    singleOrigin = "custom",
    beanType = "custom",
    grind = "custom",
    process = "custom",
    netWeightVariant = "custom",
    priceVariant = "custom",
}

export enum MetafieldsOptionsValuesEnum {
    typeOfProductListing = "[\"whole bean coffee\",\"ground coffee\",\"tea\",\"accessories\",\"equipment\"]",
    accessories = "[\"Aeropress\",\"Chemex\",\"French press\",\"Grinder\",\"Glasses\",\"Other accessories\"]",
    equipment = "[\"Drip coffee maker\",\"Espresso automatic machine\",\"Other automated extraction equipment\"]",
    organic = "[\"true\", \"false\"]",
    fairtrade = "[\"true\", \"false\"]",
    exclusiveToRoasterCup = "[\"Not exclusive\",\"Yes exclusive\"]",
    netWeight = "[\"1\",\"2000\"]",
    roasterCountry = "[\"Belgium\",\"France\",\"Germany\",\"Luxembourg\"]",
    decafYesNo = "[\"Yes\", \"No\"]",
    coffeeFlavor = "[\"Fruity\",\"Floral\",\"Green / Vegetative\",\"Nutty / Cocoa\",\"Roasted\",\"Sweet\",\"Sour / Fermented\",\"Spices\"]",
    roastType = "[\"Dark roast\",\"Medium roast\",\"Light roast\"]",
    singleOrigin = "[\"Single origin - Brazil\",\"Single origin - Colombia\",\"Single origin - Costa Rica\",\"Single origin - Ethiopia\",\"Single origin - Guatemala\",\"Single origin - Honduras\",\"Single origin - Indonesia\",\"Single origin - Jamaica\",\"Single origin - Peru\",\"Single origin - Kenya\",\"Single origin - Rwanda\",\"Single origin - other country\",\"Blend - several origins\"]",
    beanType = "[\"Robusta\",\"Arabica\",\"Mix of Arabica and Robusta\",\"Mix with other than Arabica or Robusta\"]",
    grind = "[\"Coarse (ideal for french press and chemex)\",\"Coarse to Medium (ideal for moka)\",\"Medium (ideal for filter coffee-maker and aeropress)\",\"Fine (ideal for espresso)\"]",
    process = "[\"Washed\",\"Semi-washed\",\"Natural\",\"Other\"]",
    netWeightVariant = "[\"1\",\"2000\"]",
    priceVariant = "[\"1.0\",\"200.0\"]"
}

export type Metafields = {
    key: MetafieldKeysEnum
    value: string
    value_type: MetafieldsValueType
    namespace: MetafieldsNamespaces
}

export type IProductVariantId = {
    id: number
}

export interface Product {
    body_html: string,
    created_at: string,
    handle: string,
    id: number,
    image: ProductImage,
    images: ProductImage[],
    options: ProductOptions[],
    product_type: string,
    published_at: string,
    published_scope: string,
    tags: string,
    template_suffix: string,
    title: string,
    metafields_global_title_tag: string,
    metafields_global_description_tag: string,
    updated_at: string,
    variants: ProductVariants[],
    variant: ProductVariants,
    vendor: string,
    status: ProductStatusTypes,
    available: number | string,
    metafields: Metafields[],
    price: string,
    pendingApprovals: PendingRequests[]
    files: Files,
    shouldUpdateMultiple?: boolean,
    variant_ids?: IProductVariantId[]
}

export type UpdatableProductParams = 'available' | 'price'

export type MetafieldsTitles = 
    | 'exclusiveToRoasterCup'
    | 'typeOfProductListing'
    | 'netWeight'
    | 'roasterCountry'
    | 'decafYesNo'
    | 'coffeeFlavor'
    | 'roastType'
    | 'singleOrigin'
    | 'beanType'
    | 'accessories'
    | 'equipment'
    | 'organic'
    | 'fairtrade'
    | 'grind'
    | 'netWeightVariant'
    | 'priceVariant'
    | 'process'

export enum MetafieldsTitlesEnum {
    exclusiveToRoasterCup = 'exclusiveToRoasterCup',
    typeOfProductListing = 'typeOfProductListing',
    netWeight = 'netWeight',
    roasterCountry = 'roasterCountry',
    decafYesNo = 'decafYesNo',
    coffeeFlavor = 'coffeeFlavor',
    roastType = 'roastType',
    singleOrigin = 'singleOrigin',
    beanType = 'beanType',
    accessories = 'accessories',
    equipment = 'equipment',
    organic = 'organic',
    fairtrade = 'fairtrade',
    grind = 'grind',
    netWeightVariant = 'netWeightVariant',
    priceVariant = 'priceVariant',
}

export type MetafieldsOptionsTypes = 'product' | 'variant'

export type MetafieldComponentTypes = 'input' | 'select' | 'price-input' | 'select-multiple'

export type MetafieldOptions = {
    title: MetafieldsTitles
    options?: Array<string>
    componentType: MetafieldComponentTypes
    type: MetafieldsOptionsTypes
    index?: boolean
    isBoolean?: boolean
    alwaysShow?: boolean
    isMandatory?: boolean
    showAfterTypeOfProduct?: boolean
}

export type Metafield = {
    [K in MetafieldsTitles]: string | number;
}

export type ProductDeletionTranslatedOptions =
    | "Discontinued product"
    | "Temporarily removed"
    // | "Other reason (add text)"

export type ProductDeletionPathOption =
    | 'products.manage.modal.options.0'
    | 'products.manage.modal.options.1'
    | 'products.manage.modal.options.2'

export type DeleteProductData = {
    reason: string
    variantId: VariantId
}