import * as React from 'react';
import { CircularProgress, colors } from '@mui/material';
import Box from '@mui/material/Box';

export default function CircularLoader() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
      <CircularProgress />
    </Box>
  );
}

export const CentralCircularLoader = () => (
  <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
    <CircularProgress />
  </Box>
);
