// Dependencies
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import { useSelector } from 'react-redux'

// Utils
import colorConfigs from '../../configs/colorConfigs'
import sizeConfigs from '../../configs/sizeConfigs'


type ContainerProps = {
    label: string
    onClick: () => any
}

const Button = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4vh',
    width: '4vh',
    borderRadius: '2vh',
    textTransform: 'uppercase',
    backgroundColor: alpha(colorConfigs.sidebar.bg, 0.9),
    '&:hover': {
        backgroundColor: alpha(colorConfigs.sidebar.bg, 1),
        cursor: 'pointer'
    },
    fontSize: '1.5vh',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    marginLeft: sizeConfigs.mainScreen.paddingRaw,
    marginRight: sizeConfigs.mainScreen.paddingRaw,
    marginTop: '2vh',
    marginBottom: '2vh',
}))

export default ({ label, onClick }: ContainerProps) => (
    <Button onClick={onClick}>
        {label}
    </Button>
)
