// Dependencies
import React from 'react'
import { Avatar, Box, Button, Drawer, List, Stack, Toolbar, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PersonIcon from '@mui/icons-material/Person'

// Components
import SidebarItem from './SidebarItem'
import SidebarItemCollapse from './SidebarItemCollapse'

// Utils
import assets from '../../assets'
import colorConfigs from '../../configs/colorConfigs'
import sizeConfigs from '../../configs/sizeConfigs'
import appRoutes from '../../routes/appRoutes'
import { selectShowSideMenu } from '../../redux/features/appStateSlice'
import { AUTH_URL } from '../../utils/constants-utils'
import { getLoggedUser, resetUser } from '../../redux/features/userStateSlice'
import { RouteType } from '../../routes/config'

const Sidebar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  const showSideMenu = useSelector(selectShowSideMenu)
  const user = getLoggedUser(useSelector)

  const [filteredRoutes, setFilteredRoutes] = React.useState<RouteType[]>(appRoutes.filter(route => !route.adminRoute))

  React.useEffect(() => {
    setFilteredRoutes(appRoutes.filter(route => user && (user.isAdmin || (!route.adminRoute))))
  }, [user?.name])

  return (
    <Drawer
      variant="persistent"
      open={showSideMenu}
      sx={{
        marginTop: '30px',
        flexShrink: 0,
        justifyContent: 'space-between',
        display: 'flex',
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color
        }
      }}
    >
      <List disablePadding style={{ flex: 1 }}>
        <Toolbar>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
          >
            <img src={assets.images.logo} width='100%' />
          </Stack>
        </Toolbar>
        <Stack
          sx={{
            paddingY: `${sizeConfigs.sidebar.paddingRaw / 2}px`,
            paddingX: `1vw`,
            width: '100%',
            marginBottom: '20px',
          }}
          direction="row"
          justifyContent="flex-start"
          alignItems='center'
        >
          <Avatar
            sx={{
              marginRight: '1.2vw',
              width: '2.7vw',
              height: '2.7vw',
              fontSize: '1.1vw',
              color: colorConfigs.sidebar.activeBg,
            }}
          >
            <PersonIcon />
          </Avatar>
          <Typography sx={{ fontSize: '1.3vw', fontWeight: 'medium' }}>{user?.name}</Typography>
        </Stack>
        {filteredRoutes.map((route, index) => (
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        ))}
      </List>
      <Box
        sx={{
          paddingY: `${sizeConfigs.sidebar.paddingRaw / 2}px`,
          paddingX: `1vw`,
        }}
      >
        <Button
          fullWidth
          onClick={() => {
            dispatch(resetUser())
            window.location.href = AUTH_URL
          }}
          sx={{
            color: 'white',
            backgroundColor: colorConfigs.sidebar.activeBg,
            '&:hover': {
              backgroundColor: colorConfigs.sidebar.hoverBg
            }
          }}
          variant='contained'>
            {t('sidebar.buttons.logout.label')}
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;