// Dependencies
import { AxiosResponse } from 'axios'

// Services
import { HttpService } from './http'

// Types
import { GetPendingRequestsParams, PendingRequestPayload, UpdatePendingRequestInput, RequestId, PendingRequests } from '../types/requests'

export class RequestsService extends HttpService {
    private path: string

    constructor() {
        super()
        // this.path = '/requests'
        this.path = '/approvals'
    }

    get = (params: GetPendingRequestsParams): Promise<AxiosResponse<PendingRequestPayload[]>> =>
        this.request({ method: 'get', url: this.path, params })
    
    update = (data: UpdatePendingRequestInput, id: RequestId): Promise<AxiosResponse<PendingRequests>> =>
        this.request({ method: 'put', url: `${this.path}/${id}`, data })
}