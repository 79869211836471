// Dependencies
import { AxiosResponse } from 'axios'

// Utils
import { FileUploadPayload, FileId } from '../types/files'
import { HttpService } from './http'
export class FilesService extends HttpService {
    private path: string

    constructor() {
        super()
        this.path = '/files'
    }
    
    upload = async (image: File): Promise<AxiosResponse<FileUploadPayload>> => {
        const bodyFormData = new FormData()
        bodyFormData.append('uploads', image)
        return this
            .request({
                method: 'post',
                data: bodyFormData,
                url: this.path,
                headers: { "Content-Type": "multipart/form-data" },
            })
    }

    delete = (id: FileId) =>
        this.request({ method: 'delete', url: `${this.path}/${id}` })
}
