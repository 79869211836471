// Dependencies
import React, { Fragment } from 'react'
import {
    DataGrid,
    GridActionsCellItem,
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    useGridApiRef,
} from '@mui/x-data-grid';
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { Box, Button, colors, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles'

// Components
import Modal from '../../../components/common/Modal'
import CircularLoader from '../../../components/common/CircularLoader'
import RadioButtonsList from '../../../components/common/RadioButtonsList';
import TableCell from '../../../components/common/TableCell';

// Configs
import colorConfigs from '../../../configs/colorConfigs'

// Utils
import { isProductDisabled, optionsList, productModelPriceFormatter } from '../../../utils/product-utils'
import { ProductModel, SelectFieldTypes } from '../../../types/products'

// Services
import { isLoadingSelector, isRowUpdatingSelector, rowsSelector, selectRowSelector, snackbarSelector } from './states/reducer';
import { clearFeedbackMessage, deleteProduct, getAllProducts, selectRow, setDeleteProductReason, updateProduct, updateProductError } from './states/actions';
import { TextFieldInput } from '../../../components/common/Inputs';
import { currencyMask } from '../../../utils/string-utils';
import { isImageLoadingSelector } from '../add/states/reducer';
import { ownerUserSelector } from '../../../redux/features/userStateSlice';

const ManageProductsPage = () => {
    const { t } = useTranslation()
    const apiRef = useGridApiRef()
    const dispatch = useDispatch()
    
    const ownerUser = useSelector(ownerUserSelector)
    const isRowUpdating = useSelector(isRowUpdatingSelector)
    const isLoading = useSelector(isLoadingSelector)
    const snackbar = useSelector(snackbarSelector)
    const rows = useSelector(rowsSelector)
    const selectedRow = useSelector(selectRowSelector)
    const isImageLoading = useSelector(isImageLoadingSelector)

    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [field, setField] = React.useState<string>('')
    const columns: GridColDef[] = [
        {
            field: 'product',
            headerName: t('products.manage.table.header.product'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const row: HTMLElement | null = apiRef.current.getRowElement(params.id)
                let maxHeight: number = 40
                if (!!row?.style?.maxHeight) {
                    maxHeight = parseInt(row.style?.maxHeight.replace('px', 'vw')) * 0.5
                }
                const getImgSrc: string = rows.find(r => r.id === params.id)?.img || ''
                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <img alt='' style={{ maxHeight: maxHeight || 40, maxWidth: maxHeight * 2 || 80, paddingRight: 10, }} src={getImgSrc}/>
                        <Fragment>{params.value}</Fragment>
                    </div>
                )
            },
            flex: 1,
        },
        { 
            field: 'type',
            headerName: t('products.manage.table.header.type'),
            headerClassName: 'table-header',
            valueFormatter: ({ value }) => `${value}`,
            flex: 0.8,
        },
        {
            field: 'inventory',
            headerName: t('products.manage.table.header.inventory'),
            align: 'left',
            headerAlign: 'left',
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { value } = params
                const selectedRow: ProductModel = JSON.parse(JSON.stringify(params.row))
                const field = params.field as SelectFieldTypes
                selectedRow.selectedField = field
                return (
                    <TableCell
                        disabled={isProductDisabled(params, rows)}
                        onClick={() => {
                            setShowModal(true)
                            dispatch(selectRow(selectedRow))
                            setField(selectedRow.inventory.toString())
                        }}
                        field={field}
                        values={[value]}
                        texts={[t('products.manage.table.column.inventory.adjunct')]}
                    />
                )
            },
            flex: 0.35,
        },
        {
            field: 'price',
            headerName: t('products.manage.table.header.price'),
            align: 'left',
            headerAlign: 'left',
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const value = productModelPriceFormatter(params.value)
                const selectedRow: ProductModel = JSON.parse(JSON.stringify(params.row))
                const field = params.field as SelectFieldTypes
                selectedRow.selectedField = field
                const values = [value.main]

                if (value.requested)
                    values.push(value.requested)

                return (
                    <TableCell
                        disabled={isProductDisabled(params, rows)}
                        field={field}
                        onClick={() => {
                            setShowModal(true)
                            dispatch(selectRow(selectedRow))
                            setField(currencyMask(selectedRow.price.requested || selectedRow.price.main))
                        }}
                        texts={Array(values.length).fill('')}
                        values={values}
                    />
                )
            },
            flex: 0.35,
        },
        {
            field: 'preDiscountPrice',
            // headerName: t('products.manage.table.header.preDiscountPrice'),
            align: 'left',
            headerAlign: 'left',
            headerClassName: 'table-header',
            valueFormatter: ({ value }) => value ? `€ ${parseFloat(value).toFixed(2)}` : '',
            renderHeader(params) {
                const text = t('products.manage.table.header.preDiscountPrice')
                return (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: colorConfigs.topbar.bg,
                            justifyContent: 'center',
                        }}
                    >
                        {text.split('\n').map((t, index) => (
                            <Typography 
                                key={index}
                                sx={{
                                    fontSize: '1vw',
                                    lineHeight: '1.1vw',
                                    fontWeight: 'medium'
                                }}
                            >
                                {t} 
                            </Typography>
                        ))}
                    </div>
                )
            },
            flex: 0.4,
        },
        {
            field: 'action',
            headerName: t('products.manage.table.header.actions.remove'),
            type: 'actions',
            getActions: (params: GridRowParams) => ([
                <GridActionsCellItem
                    id="action-cell-item"
                    key={params.id}
                    disabled={isProductDisabled(params, rows)}
                    icon={<Delete style={{ width: '1.8vw' }} />}
                    onClick={() => {
                        const selectedRow: ProductModel = JSON.parse(JSON.stringify(params.row))
                        selectedRow.selectedField = 'action'
                        setShowModal(true)
                        dispatch(selectRow(selectedRow))
                    }}
                    label='Delete'
                />,
            ]),
            headerClassName: 'table-header',
            flex: 0.25,
        }
    ]

    React.useEffect(() => {
        const get = () => {
            dispatch(getAllProducts({ useLoading: false }))
        }
        // get()
        const interval = setInterval(() => get(), 20000)
        return () => {
          clearInterval(interval);
        }
    }, [])

    React.useEffect(() => {
        const updatedColumns = columns.map(item => {           
            const string = item.field !== 'action' ? item.field : 'actions.remove'
            item.headerName = t(`products.manage.table.header.${string}`)
            return item
        })
        apiRef.current?.updateColumns(updatedColumns)
    }, [t])

    React.useEffect(() => {
        dispatch(getAllProducts({ useLoading: true }))
    }, [dispatch, ownerUser?.ownerId])

    const handleCloseSnackbar = () => dispatch(clearFeedbackMessage(null))

    const handleProcessRowUpdateError = React.useCallback(() => {
        dispatch(updateProductError({ children: t('products.manage.feedback.save.error'), severity: 'error' }))
    }, [dispatch, t]);

    const handleClose = React.useCallback(() => {
        setShowModal(false)
        setField('')
        dispatch(selectRow({}))
        dispatch(setDeleteProductReason(''))
    }, [dispatch])

    const isButtonDisabled = React.useCallback((): boolean => {
        if (selectedRow.id && field) {
            let value = selectedRow.inventory
            if (selectedRow.selectedField === 'price') {
                value = selectedRow.price?.requested || selectedRow.price?.main
            }
            let compareValue = field.replace('€', '')
            if (
                parseFloat(compareValue) === parseFloat(value as string) ||
                (selectedRow.selectedField === 'price' && !compareValue.replace('.', '').replace(',', '').replace(/\D/g, '').replaceAll('0', ''))
            ) {
                return true
            }
            return false
        }
        return true
    }, [field, selectedRow])

    if (isLoading || isImageLoading) {
        return <CircularLoader />
    }

    return (
        <Box
            sx={{
                width: '100%',
                // display: 'flex',
                // height: 'auto',
                // overflowX: 'scroll',
                // flexWrap: 'nowrap',
                '& .table-header': {
                    backgroundColor: colorConfigs.topbar.bg,
                    fontSize: '1vw',
                },
                '& .disabled': {
                    backgroundColor: alpha(colors.grey['300'], 0.4),
                    fontSize: '0.9vw',
                    padding: 2,
                },
                '& .row': {
                    fontSize: '0.9vw',
                    padding: 2,
                },
            }}
        >    
            <DataGrid
                getRowId={(row) => `${row.id}${row.variantId}`}
                rows={rows}
                columns={columns}
                autoHeight
                getCellClassName={(params: GridCellParams<any, any, number>) => {
                    if (isProductDisabled(params, rows)) {
                        return 'disabled'
                    }
                    return 'row'
                }}
                showCellVerticalBorder
                columnThreshold={20}
                showColumnVerticalBorder
                disableColumnFilter
                hideFooterPagination
                hideFooter
                disableColumnMenu
                loading={isRowUpdating}
                apiRef={apiRef}
                onProcessRowUpdateError={handleProcessRowUpdateError}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={3000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
            {showModal && (
                <Modal
                    show={showModal}
                    handleClose={handleClose}
                    children={(
                        <React.Fragment>
                            {selectedRow.selectedField === 'action' && (
                                <RadioButtonsList
                                    onSend={() => dispatch(deleteProduct(selectedRow as ProductModel))}
                                    handleClose={handleClose}
                                    buttonLabel={t('products.manage.modal.button.label')}
                                    title={t('products.manage.modal.title')}
                                    options={optionsList}
                                />      
                            )}
                            {selectedRow.selectedField !== 'action' && (
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: '1.3vw',
                                            fontWeight: 'medium',
                                            color: colors.grey['800'],
                                        }}
                                    >
                                        {selectedRow.selectedField === 'price' ? t('products.manage.modal.price.title') : t('products.manage.modal.inventory.title')}
                                    </Typography>
                                    <TextFieldInput
                                        itemkey={selectedRow.id?.toString()}
                                        sx={{
                                            backgroundColor: colorConfigs.mainBg,
                                            '& .MuiInputBase-root': {
                                                fontSize: '1.1vw',
                                            },
                                            marginY: 2,
                                        }}
                                        fullWidth
                                        value={field}
                                        onChange={(e) => {
                                            if (selectedRow.selectedField === 'inventory' && parseFloat(e.target.value) >= 0) {
                                                setField(e.target.value)
                                            } else {
                                                setField(currencyMask(e.target.value))
                                            }
                                        }}
                                        type={selectedRow.selectedField === 'inventory' ? 'number' : 'text'}
                                    />
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        sx={{ backgroundColor: colorConfigs.sidebar.bg }}
                                        disabled={isButtonDisabled()}
                                        onClick={() => {
                                            dispatch(updateProduct({
                                                row: selectedRow as ProductModel,
                                                value: selectedRow.selectedField === 'inventory'
                                                    ? parseFloat(field?.replace('€', ''))
                                                    : parseFloat(field?.replace('€', '')).toFixed(2)
                                            }))
                                            handleClose()
                                        }}
                                    >
                                        {selectedRow.selectedField === 'price' ? t('products.manage.modal.price.button') : t('products.manage.modal.inventory.button')}
                                    </Button>
                                </>
                            )}
                        </React.Fragment>
                    )}
                />
            )}
        </Box>
    );
}

export default ManageProductsPage