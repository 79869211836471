export default {
    translations: {
        general: {
            apiError: "Erreur de requête, veuillez réessayer...",
        },
        searchbar: {
            loading: "Chargement",
            noResults: "Aucun résultat trouvé",
        },
        sidebar: {
            products: {
                manage: "Gérer vos produits",
                add: "Rajouter un produit",
            },
            orders: {
                manage: "Gérer les commandes",
            },
            requests: {
                main: "Demandes",
                "delete-product": "Supprimer un produit",
                "change-price": "Changer un prix",
            },
            buttons: {
                logout: {
                    label: 'déconnecter',
                },
            },
        },
        topbar: {
            selector: {
                label: "Langue",
            },
        },
        languages: {
            english: "Anglais",
            german: "Allemand",
            french: "Français",
        },
        orders: {
            modal: {
                button: {
                    label: "Envoyer",
                },
                title: "Reason to reject",
                title2: "Raison du rejet de la commande",
                options: {
                    0: "Produit en rupture de stock",
                    1: "Pénurie de main-d'œuvre",
                    2: "Pénurie de matériaux d'emballage",
                },
                title3: 'Combien de boîtes avez-vous utilisées pour emballer cette commande?',
            },
            status: {
                new: "nouveau",
                pending: "en attente",
                packaged: "emballée",
                completed: "envoyée",
                declined: "declinée",
            },
            feedback: {
                statusUpdate: {
                    success: "La commande est {{status}}",
                    error: "Erreur lors de mise à jour du status de la commande",
                },
                print: {
                    error: "Error while downloading order details",
                },
            },
            cards: {
                orderId: "Commande #",
                date: "Date",
                price: "Coût",
                amount: "Quantité total",
                product: "Produit",
                weight: "Poids",
                location: "L'emplacement",
                orderNumber: "Shopify #",
                type: "Variante",
                totalAmount: "Quantité total",
                buttons: {
                    reject: "rejetée",
                    accept: "acceptée",
                    ready: "prête",
                    complete: "envoyée",
                    pending: "en attente",
                    packaged: "emballée",
                    moveTo: "passer à",
                    print: "imprimer",
                }
            },
            configurations: {
                switches: {
                    date: {
                        label: "Montrer date",
                    },
                    amount: {
                        label: "Visualiser la quantité totale",
                    },
                    status: {
                        label: "Visualiser les commandes refusées",
                    },
                    priority: {
                        label: "Visualiser les commandes prioritaires",
                    },
                },
                filters: {
                    date: {
                        label: "Filtrer par date",
                        from: "Date de",
                        to: "Date à",
                    },
                },
            },
            buttons: {
                exportCsv: "Exporter les commandes en attentes",
                moveAllToCompleted: "Mettre toutes les commandes en 'envoyées'",
            }
        },
        products: {
            add: {
                sections: {
                    general: {
                        title: "Informations Générales",
                        inputs: {
                            first: {
                                label:"Nom du produit",
                                placeholder: "",
                            },
                            second: {
                                label: "Description",
                            },
                        },
                    },
                    media: {
                        title: "Média",
                        buttons: {
                            first: "Charger un nouveau média",
                        },
                        description: {
                            first: "Taille limite 3 MB",
                            second: "Format acceptés: .png ou jpg",
                        },
                    },
                    pricing: {
                        title: "Prix",
                        inputs: {
                            first: {
                                label: "Prix en magasin",
                            },
                        },
                    },
                    metafields: {
                        title: "Caractéristiques du Produit",
                        fields: {
                            exclusiveToRoasterCup: {
                                title: "Exclusif à RoasterCup?",
                                options: {
                                    0: "Oui",
                                    1: "Non",
                                },
                            },
                            typeOfProductListing: {
                                title: "Type de produit",
                                options: {
                                    0: "Café en grains",
                                    1: "Café moulu",
                                    2: "Thé",
                                    3: "Accessoires",
                                    4: "Equipement",
                                },
                            },
                            netWeight: {
                                title: "Poids net",
                                placeholder: "000 grammes",
                            },
                            roasterCountry: {
                                title: "Pays du torréfacteur",
                                options: {
                                    0: "Belgique",
                                    1: "France",
                                    2: "Allemagne",
                                    3: "Luxembourg",
                                },
                            },
                            decafYesNo: {
                                title: "Décaféiné?",
                                options: {
                                    0: "Oui",
                                    1: "Non",
                                },
                            },
                            coffeeFlavor: {
                                title: "Arôme du café",
                                options: {
                                    0: "Fruité",
                                    1: "Floral",
                                    2: "Végétal",
                                    3: "Noisette / Cacao",
                                    4: "Corsé",
                                    5: "Doux",
                                    6: "Aigre / Fermenté",
                                    7: "Epicé",
                                },
                            },
                            roastType: {
                                title: "Type de Torréfaction",
                                options: {
                                    0: "Torréfaction claire",
                                    1: "Torréfaction moyenne",
                                    2: "Torréfaction foncée",
                                },
                            },
                            singleOrigin: {
                                title: "Pure Origine",
                                options: {
                                    0: "Pure origine - Brésil",
                                    1: "Pure origine - Colombie",
                                    2: "Pure origine - Costa Rica",
                                    3: "Pure origine - Éthiopie",
                                    4: "Pure origine - Guatemala",
                                    5: "Pure origine - Honduras",
                                    6: "Pure origine - Indonésie",
                                    7: "Pure origine - Jamaïque",
                                    8: "Pure origine - Kenya",
                                    9: "Pure origine - Pérou",
                                    10: "Pure origine - Rwanda",
                                    11: "Mélange de cafés - Plusieurs origines",
                                    12: "Pure origine - Autre pays",
                                },
                            },
                            beanType: {
                                title: "Variété de grains",
                                options: {
                                    0: "Arabica",
                                    1: "Robusta",
                                    2: "Mélange d'Arabica et de Robusta",
                                    3: "Mix qui contient des grains autres que arabica et robusta",
                                },
                            },
                            accessories: {
                                title: "Accessoires",
                                options: {
                                    0: "Aéropress",
                                    1: "Chemex",
                                    2: "Cafetière à piston",
                                    3: "Broyeurs",
                                    4: "Verres",
                                    5: "Autres accessoires",
                                },
                            },
                            equipment: {
                                title: "Équipement",
                                options: {
                                    0: "Cafetière à filtre",
                                    1: "Machine à café automatiques",
                                    5: "Autres équipements d'extraction manuels",
                                },
                            },
                            organic: {
                                title: "Organique (Bio)",
                                options: {
                                    0: "Oui",
                                    1: "Non",
                                },
                            },
                            fairtrade: {
                                title: "Fairtrade",
                                options: {
                                    0: "Oui",
                                    1: "Non",
                                },
                            },
                            grind: {
                                title: "Mouture",
                                options: {
                                    0: "Grossière (idéale pour les cafetières à piston et chemex)",
                                    1: "Grossière à Moyenne (idéale pour les cafetières à filtre)",
                                    2: "Moyenne à fine (idéale pour les cafetières italienne)",
                                    3: "Fine (idéal pour espresso)",
                                },
                            },
                            process: {
                                title: "Process",
                                options: {
                                    0: "Lavé",
                                    1: "Semi-lavé",
                                    2: "Naturel",
                                    3: "Autre",
                                },
                            },  
                            netWeightVariant: {
                                title: "Poids net",
                                placeholder: "000 grammes",
                            },
                            priceVariant: {
                                title: "Prix en magasin",
                                placeholder: "€ 0.00",
                            }
                        },
                        menu: {
                            product: "Produit",
                            variant: "Variante",
                        },
                    },
                },
                buttons: {
                    save: "Sauvegarder",
                    showAll: "Afficher tout",
                    hide: "Cacher",
                },
                feedback: {
                    save: {
                        success: "Produit ajouté avec succès!",
                        error: "Une erreur est survenu en ajoutant le produit, veuillez réessayer!",
                    },
                },
            },
            manage: {
                modal: {
                    button: {
                        label: "Envoyer"
                    },
                    title: "Raison de la suppression",
                    options: {
                        0: "Produit retiré de la vente",
                        1: "Temporairement supprimé",
                        2: "Autre raison (veuillez développer)",
                    },
                    price: {
                        title: "Demande de changement de prix",
                        button: "Soumettre",
                    },
                    inventory: {
                        title: "Mettre à jour l'inventaire",
                        button: "Soumettre",
                    },
                },
                feedback: {
                    save: {
                        success: "Produit enregistré avec succès",
                        error: "Erreur lors de l'enregistrement",
                    },
                    remove: {
                        success: "Produit supprimé avec succès",
                        error: "Erreur lors de la suppression du produit",
                    }
                },
                table: {
                    header: {
                        product: "Produit",
                        inventory: "Inventaire",
                        category: "Catégorie",
                        type: "Variant",
                        price: "Prix de vente",
                        preDiscountPrice: "Prix avant \n réduction",
                        actions: {
                            remove: "Supprimer",
                        }
                    },
                    column: {
                        inventory: {
                            adjunct: "en stock",
                        },
                        category: {
                            coffee: "café"
                        },
                    },
                },
            },
        },
        requests: {
            noResults: "Aucune demande trouvée...",
            feedback: {
                update: {
                    success: "Demande MAJ avec succès",
                    error: "Erreur lors de la MAJ de la demande",
                },
            },
            cards: {
                buttons: {
                    approve: {
                        title: "Valider",
                    },
                    reject: {
                        title: "Rejeter",
                    },
                },
                product: "Produit",
                roaster: "Torréfacteur",
                reason: "Raison",
                requestedPrice: "Prix demandé",
                currentPrice: "Prix actuel",
            },
        },
    },
}