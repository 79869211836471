import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export type Options = {
    label: string
    value: string | number
}

export type SelectorProps = BoxProps & {
    label?: string
    options: Array<Options>
    handleSelect: (value: string) => void
    initialValue?: string
    disabled?: boolean
    value?: any
}

const Selector = ({ label, options = [], handleSelect, initialValue = '', disabled, ...props }: SelectorProps) => {
    const [value, setValue] = React.useState<string>(initialValue);

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value)
        handleSelect(event.target.value as string);
    };

    return (
        <Box sx={{ ...props.sx }} {...{ props }}  key={props.key && `box-select-${props.key}`}>
            <FormControl fullWidth  size="small" key={props.key && `form-select-${props.key}`}>
                <Select
                    key={props.key && `select-${props.key}` || Math.random()}
                    disabled={disabled}
                    value={props.value || props.value === '' ? props.value : value}
                    onChange={handleChange}
                    sx={{
                        fontSize: '1.1vw',
                    }}
                >   
                    {options.map(opt =>
                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}

export type MultipleSelectorProps = BoxProps & {
    label?: string
    options: Array<Options>
    handleSelectMultiple: (value: string | string[]) => void
    initialValue?: string
    disabled?: boolean
    value?: any
}

export const MultipleSelector = ({ label, options = [], handleSelectMultiple, disabled, ...props }: MultipleSelectorProps) => {
    const [values, setValues] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof values>) => {
        const {
            target: { value },
        } = event
        const vals = typeof value === 'string' ? value.split(',') : value
        setValues(vals)
        handleSelectMultiple(vals)
    };

    return (
        <Box sx={{ ...props.sx }} {...{ props }}  key={props.key && `box-select-${props.key}`}>
            <FormControl fullWidth  size="small" key={props.key && `form-select-${props.key}`}>
                <Select
                    labelId="select-multiple-label"
                    id="select-multiple"
                    key={props.key && `select-${props.key}`}
                    disabled={disabled}
                    value={values}
                    onChange={handleChange}
                    multiple
                    sx={{
                        fontSize: '1.1vw',
                    }}
                >   
                    {options.map(opt =>
                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}

export default Selector