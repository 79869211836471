import i18n, { TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import translations from './locales'

export const defaultLanguage = 'fr'

const i18nConfig = {
  resources: translations,
  fallbackLng: defaultLanguage,
  defaultNS: 'translations' // default namespace
}

i18n
  .use(initReactI18next)
  .init(i18nConfig)

export const translate = (key: string, options?: TOptions) =>
  i18n.t(key, options);

export const translateToLng = (key: string, lng: string = 'en-US', options?: TOptions) =>
  i18n.t(key, { ...options, lng })

export default i18n