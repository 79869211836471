// Dependencies
import * as React from 'react'
import {
    colors,
    SxProps,
    Modal as MuiModal,
    Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// Utils
import colorConfigs from '../../configs/colorConfigs'

const padding = '2vw'

const style: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
    bgcolor: colorConfigs.mainBg,
    p: padding,
    borderRadius: 3,
};

type ModalProps = {
    show: boolean
    handleClose: () => void
    children?: React.ReactNode
}

const Modal = ({ show, handleClose, children }: ModalProps) => (
    <MuiModal
        open={show}
        onClose={handleClose}
    >
        <Box sx={style}>
            <div style={{ position: 'absolute', right: padding, zIndex: 100 }} onClick={handleClose}>
                <CloseIcon sx={{ fontSize: '1.7vw', color: colors.grey['800'] }} />
            </div>
            {children}
        </Box>
    </MuiModal>
);

export default Modal