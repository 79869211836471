const sizeConfigs = {
  sidebar: {
    width: '20%',
    widthRaw: 20,
    font: '17px',
    padding: '24px',
    paddingRaw: 24,
  },
  topbar: {
    font: '20px',
  },
  mainScreen: {
    padding: '24px',
    paddingRaw: 24,
    width: '100%',
    widthRaw: 100,
  },
  orderCard: {
    fontSizeRaw: 12,
    button: {
      fontSize: '9px',
      widthRaw: 100,
      iconHeight: '13px'
    }
  }
};

export default sizeConfigs;