export const firstLetterTypedAlwaysCapital = (str: string): string => {
    const size = str.length
    let modStr = ''
    if (size === 1) {
        return str.toUpperCase()
    } else if (str[size - 2] === ' ') {
        modStr = `${str.slice(0, size - 1)}${str[size - 1].toUpperCase()}`
        return modStr
    }
    return str
}

export const firstLetterTypedCapital = (str: string): string => {
    const size = str.length
    if (size === 1) {
        return str.toUpperCase()
    }
    return str
}

export const capitalizeTextFirstLetter = (text: string): string =>
    !!text.length
        && text[0].toUpperCase() + text.slice(1)
        || text

export const removeCurrencyMask = (value: string): string => Number(value.replace(/[^0-9\.-]+/g,"")).toFixed(2).toString()

export const currencyMask = (value: string | any, currency: string = 'EUR'): string => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
    if (!value || value === '00') return ''
    if (!value) return ''
    const options = { minimumFractionDigits: 2, style: 'currency', currency: currency }
    const number = parseFloat(value)
    const result = new Intl.NumberFormat('en-US', options).format(number / 100)
    return result
}

