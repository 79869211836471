// Dependencies
import React from 'react'
import { alpha, colors, styled, SxProps, Typography} from '@mui/material'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Utils
import colorConfigs from '../../configs/colorConfigs'
import { deleteImage, setFields, uploadImage } from '../../pages/products/add/states/actions'
import { imageListSelector, isImageLoadingSelector } from '../../pages/products/add/states/reducer'
import CircularLoader from './CircularLoader'
import { deepCloneObject } from '../../utils/object-utils'

type ContainerProps = {
    isDragging: boolean
    isUploading: boolean
}

const Container = styled('div')<ContainerProps>(({ theme, isDragging, isUploading }) => {
    let style: SxProps = {
        position: 'relative',
        backgroundColor: !isDragging ? colorConfigs.mainBg : alpha(colorConfigs.sidebar.bg, 0.1),
        width: '100%',
        height: '15vw', 
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.grey['200'],
            cursor: 'pointer',
        },
        borderColor: !isDragging ? colors.grey['300'] : alpha(colorConfigs.sidebar.bg, 0.1),
        borderWidth: '1px',
        borderStyle: 'solid',
    }

    if (isUploading) {
        style = {
            ...style,
            backgroundImage:
                `linear-gradient(90deg, ${colorConfigs.sidebar.bg} 50%, transparent 50%),
                linear-gradient(90deg, ${colorConfigs.sidebar.bg} 50%, transparent 50%),
                linear-gradient(0deg, ${colorConfigs.sidebar.bg} 50%, transparent 50%),
                linear-gradient(0deg, ${colorConfigs.sidebar.bg} 50%, transparent 50%)`,
            backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
            backgroundSize: '15px 2px, 15px 2px, 2px 15px, 2px 15px',
            backgroundPosition: 'left top, right bottom, left bottom, right top',
            animation: 'border-dance 1s infinite linear',
            '@keyframes border-dance': {
                '0%': {
                    backgroundPosition: 'left top, right bottom, left bottom, right top',
                },
                '100%': {
                    backgroundPosition: 'left 15px top, right 15px bottom , left bottom 15px , right top 15px',
                }
            }
        }
    }
    return style
})

const Button = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '0.6vw',
    borderRadius: 3,
    boxShadow: `${alpha(theme.palette.common.black, 0.25)}  0.1rem 0.1rem`,
    borderColor: theme.palette.common.black,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1vw',
    color: colors.grey['800'],
}))

const DeleteImageIconWrapper = styled('div')(() => ({
    position: 'absolute',
    padding: '1vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
}))

const ImageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '13vw',
    width: '15vw',
    padding: '1vw',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
}))

export default () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isImageLoading = useSelector(isImageLoadingSelector)
    const imageListState = useSelector(imageListSelector)
    const maxNumber = 1

    const onChange = React.useCallback(
        (imageList: ImageListType) => {
            dispatch(setFields({ imageList: deepCloneObject(imageList) }))
            if (imageList[0]?.file)
                dispatch(uploadImage(imageList[0].file))
        },
        []
    )

    return (
        <div>
            <ImageUploading
                acceptType={['jpg', 'png']}
                value={imageListState}
                onChange={onChange}
                maxNumber={maxNumber}
                // maxFileSize={}
                // allowNonImageType
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <Container isDragging={isDragging} {...dragProps} isUploading={isImageLoading}>
                        {(!!imageListState.length && !isImageLoading) && (
                            <DeleteImageIconWrapper
                                onClick={() => {
                                    onImageRemove(0)
                                    dispatch(deleteImage())
                            }}>
                                <ClearIcon sx={{ fontSize: '1.5vw', color: colors.grey['700'] }}/>
                            </DeleteImageIconWrapper>
                        )}
                        {isImageLoading ? (
                            <CircularLoader />
                        ) : (
                            <React.Fragment>
                                {(!imageListState.length && !isDragging) && (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                                        <Button
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            style={{
                                                zIndex: 100
                                            }}
                                        >
                                            {t('products.add.sections.media.buttons.first')}
                                        </Button>
                                        <Typography sx={{ marginTop: '1vw', color: colors.grey[800], fontSize: '1.2vw' }}>{t('products.add.sections.media.description.first')}</Typography>
                                        <Typography sx={{ marginTop: '0.5vw', color: colors.grey[800],  fontSize: '1.2vw' }}>{t('products.add.sections.media.description.second')}</Typography>
                                    </div>
                                )}
                                {imageList.map((image, index) => (
                                    <ImageWrapper key={index}>
                                        <img src={image.dataURL} alt="" width="100%" />
                                    </ImageWrapper>
                                ))}
                            </React.Fragment>
                        )}
                    </Container>
                )}
            </ImageUploading>
        </div>
    );
}
