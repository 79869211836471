// Dependencies
import LocalMallIcon from '@mui/icons-material/LocalMall'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import QueueIcon from '@mui/icons-material/Queue'
import { Outlet } from 'react-router-dom'

// Pages
import ManageProductsPage from '../pages/products/manage/ManageProductsPage'
import OrdersPage from '../pages/orders/OrdersPage'
import AddProductPage from '../pages/products/add/AddProductsPage'
import RequestsPage from '../pages/requests/RequestsPage'

// Utils
import { RouteType } from './config';

const appRoutes: RouteType[] = [
  {
    path: '/products/add',
    element: <AddProductPage />,
    state: 'products.add',
    sidebarProps: {
      displayText: 'Add',
      icon: <AddCircleIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.products.add',
    },
  },
  {
    path: '/products/manage',
    element: <ManageProductsPage />,
    state: 'products.manage',
    sidebarProps: {
      displayText: 'Manage Products',
      icon: <LocalMallIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.products.manage',
    },
  },
  {
    path: '/orders/manage',
    element: <OrdersPage />,
    state: 'orders.manage',
    sidebarProps: {
      displayText: 'Orders',
      icon: <ListAltIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.orders.manage',
    },
  },
  {
    path: '/requests',
    element: <Outlet />,
    state: 'requests',
    sidebarProps: {
      icon: <QueueIcon sx={{ width: '1.8vw' }} />,
      textPath: 'sidebar.requests.main',
    },
    adminRoute: true,
    child: [
      {
        path: '/requests/delete-product',
        element: <RequestsPage />,
        state: 'requests.delete-product',
        sidebarProps: {
          textPath: 'sidebar.requests.delete-product',
        },
      },
      {
        path: '/requests/change-price',
        element: <RequestsPage />,
        state: 'requests.change-price',
        sidebarProps: {
          textPath: 'sidebar.requests.change-price',
        },
      },
    ]
  }
];

export default appRoutes

