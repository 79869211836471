// Types
import { Product, ProductDeletionTranslatedOptions, ProductModel } from '../../../../types/products';
import { ManageProductsActionsTypes } from './types';

export function selectRow(
    payload: Partial<ProductModel> 
) {
    return {
        type: ManageProductsActionsTypes.SELECT_ROW,
        payload,
    }
}

export function deleteProduct(
    payload: ProductModel,
) {
    return {
        type: ManageProductsActionsTypes.DELETE,
        payload,
    }
}

export function deleteProductSuccess(
    payload: {
        products: Product[],
        rows: ProductModel[],
        snackbar: any,
    }
) {
    return {
        type: ManageProductsActionsTypes.DELETE_SUCCESS,
        payload,
    }
}

export function deleteProductError(
    payload: any,
) {
    return {
        type: ManageProductsActionsTypes.DELETE_ERROR,
        payload,
    }
}

export function updateProduct(
    payload: {
        row: ProductModel,
        value: number | string,
    }
) {
    return {
        type: ManageProductsActionsTypes.UPDATE_INVENTORY,
        payload,
    }
}

export function updateProductSuccess(
    payload: {
        rows: ProductModel[];
        products: Product[];
        snackbar: any;
    }
) {
    return {
        type: ManageProductsActionsTypes.UPDATE_INVENTORY_SUCCESS,
        payload,
    }
}

export function updateProductError(
    payload: any
) {
    return {
        type: ManageProductsActionsTypes.UPDATE_INVENTORY_ERROR,
        payload,
    }
}

export function getAllProducts(
    payload: {
        useLoading?: boolean
    }
) {
    return {
        type: ManageProductsActionsTypes.GET_ALL,
        payload,
    }
}

export function getAllProductsSuccess(
    payload: {
        products: Product[];
        rows: ProductModel[]
    }
) {
    return {
        type: ManageProductsActionsTypes.GET_ALL_SUCCESS,
        payload,
    }
}

export function getAllProductsError(
    payload: any,
) {
    return {
        type: ManageProductsActionsTypes.GET_ALL_ERROR,
        payload,
    }
}

export function clearFeedbackMessage(
    payload: any,
) {
    return {
        type: ManageProductsActionsTypes.CLEAR_FEEDBACK_MESSAGE,
        payload,
    }
}

export function setDeleteProductReason(
    payload: ProductDeletionTranslatedOptions | string
) {
    return {
        type: ManageProductsActionsTypes.DELETE_REASON,
        payload,
    }
}
