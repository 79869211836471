// Types
import { FileId, FileUploadPayload } from '../../../../types/files';
import { Metafield } from '../../../../types/products';
import { addProductState } from './reducer';
import { AddProductActionsTypes } from './types';

export function addProduct(
    payload: {
        title: string,
        description: string,
        image: string,
        pricing: string,
        metafields: Metafield
    }
) {
    return {
        type: AddProductActionsTypes.ADD_PRODUCT,
        payload,
    }
}

export function addProductSuccess(
    payload: any,
) {
    return {
        type: AddProductActionsTypes.ADD_PRODUCT_SUCCESS,
        payload,
    }
}

export function addProductError(
    payload: any,
) {
    return {
        type: AddProductActionsTypes.ADD_PRODUCT_ERROR,
        payload,
    }
}

export function setFields(
    payload: Partial<addProductState>
) {
    return {
        type: AddProductActionsTypes.SET_FIELDS,
        payload,
    }
}

export function uploadImage(
    payload: File
) {
    return {
        type: AddProductActionsTypes.UPLOAD_IMAGE,
        payload,
    }
}

export function uploadImageSuccess(
    payload: FileUploadPayload
) {
    return {
        type: AddProductActionsTypes.UPLOAD_IMAGE_SUCCESS,
        payload,
    }
}

export function uploadImageError(
    payload: any
) {
    return {
        type: AddProductActionsTypes.UPLOAD_IMAGE_ERROR,
        payload,
    }
}

export function deleteImage() {
    return {
        type: AddProductActionsTypes.DELETE_IMAGE,
    }
}

export function deleteImageSuccess() {
    return {
        type: AddProductActionsTypes.DELETE_IMAGE_SUCCESS,
    }
}

export function deleteImageError(
    payload: any
) {
    return {
        type: AddProductActionsTypes.DELETE_IMAGE_ERROR,
        payload,
    }
}