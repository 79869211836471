// Dependencies
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Utils
import { selectCurrentPath } from '../redux/features/appStateSlice'
import { selectedCardOrdersSelector } from '../pages/orders/states/reducer'
import { isObjectEmpty } from './object-utils'
import { handleSwitches, selectMultipleOrders } from '../pages/orders/states/actions'

export const WatchNavigationStates = () => {
    const dispatch = useDispatch()

    const currentPath = useSelector(selectCurrentPath)
    const selectedCardOrders = useSelector(selectedCardOrdersSelector)

    React.useEffect(() => {
        if (!isObjectEmpty(selectedCardOrders) && currentPath !== 'orders.manage') {
            dispatch(selectMultipleOrders(null))
        }
        dispatch(handleSwitches(null))
    }, [currentPath, selectedCardOrders, dispatch])

    return null
}