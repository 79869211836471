import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import IosShareIcon from '@mui/icons-material/IosShare'
import moment from 'moment'
import { useSelector } from 'react-redux'
import CsvDownloader from 'react-csv-downloader'

// Components
import OrdersCard from './OrdersCard'

// Utils
import {
    ordersStatus,
    checkOrderStatus,
    mapOrderStatusToColor,
    findOrderByStatus,
} from '../../utils/orders-utils'
import { OrderStatusTypes, OrderModel, OrderCsvData } from '../../types/orders'
import { filteredOrdersSelector, switchesSelector } from '../../pages/orders/states/reducer';

const HeaderItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.75),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.75),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 0,
    fontSize: '1.2vw'
}));

const StyledStack = styled(Stack)(({ theme }) => ({
    ...theme.typography.body2,
    marginRight: theme.spacing(0.75),
    marginTop: theme.spacing(0.75),
}))

type OrdersContainerProps = {
    orders: Array<OrderModel>
    csv: Partial<OrderCsvData>
};

const OrdersContainer = ( { orders = [], csv }: OrdersContainerProps) => {
    const { t } = useTranslation()

    const filteredOrders = useSelector(filteredOrdersSelector)
    const switchesStates = useSelector(switchesSelector)
    const counterInitialState: {[param in OrderStatusTypes]: number} = {
        new: 0,
        pending: 0,
        packaged: 0,
        completed: 0,
        declined: 0,
    }
    
    const [filteredOrderStatus, setFilteredOrderStatus] = React.useState<OrderStatusTypes[]>(ordersStatus)
    const [statusCounter, setStatusCounter] = React.useState<{[param in OrderStatusTypes]: number}>(counterInitialState)

    // const handleScroll = (event: { target: any; deltaY: any; }) => {
    //     const container = event.target;
    //     const scrollAmount = event.deltaY;
    //     container.scrollTo({
    //       top: 0,
    //       left: container.scrollLeft + scrollAmount,
    //       behavior: 'smooth'
    //     });
    // }

    React.useEffect(() => {
        if (!switchesStates.showDeclinedOrders) {
            setFilteredOrderStatus(() => ordersStatus.filter(status => status !== 'declined'))
        } else {
            setFilteredOrderStatus(ordersStatus)
        }
    }, [switchesStates])

    React.useEffect(() => {
        const obj = counterInitialState
        filteredOrders.forEach(order => {
            obj[order.status] += 1
        })
        setStatusCounter(obj)
    }, [filteredOrders])

    const getOrderByStatus = React.useCallback(
        (status: OrderStatusTypes) =>
            findOrderByStatus(filteredOrders, status),
        [filteredOrders]
    )

    return (
        <div 
            style={{
                width: '100%',
                display: 'flex',
                // overflowX: 'scroll',
                // flexWrap: 'nowrap'
            }}
            // onWheel={handleScroll}
        >
            {filteredOrderStatus.map(val => (
                <div style={{ flex: 1 }} key={val}>
                {/* <div key={val} style={{ minWidth: showSideMenu ? '19vw' : '23vw', maxWidth: showSideMenu ? '19vw' : '23vw' }} > */}
                    <HeaderItem
                        sx={{
                            backgroundColor: mapOrderStatusToColor[val],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                        variant="outlined"
                    >
                        {`(${statusCounter[val]}) `}
                        {t(`orders.status.${val}`)}
                        {((val === 'pending' || val === 'completed') && getOrderByStatus(val)) && (
                            <CsvDownloader
                                datas={csv[val]?.datas || []}
                                columns={csv[val]?.columns}
                                filename={`pending-orders (${moment().format('L')})`}
                                separator=";"
                                style={{ position: 'absolute', right: '1vw', cursor: 'pointer' }}
                            >
                                <IosShareIcon sx={{ fontSize: '1.4vw' }} />
                            </CsvDownloader>
                        )}
                    </HeaderItem>
                    <StyledStack>
                        {orders.map((order, index) =>
                            checkOrderStatus(order, val) && (
                                <OrdersCard
                                    order={order}
                                    key={index}
                                />
                            )
                        )}
                    </StyledStack>
                </div>
            ))}
        </div>
    )
}

export default OrdersContainer