// Dependencies
import * as React from 'react'
import {
  Card,
  CardContent,
  Typography,
  colors,
  CircularProgress,
} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Utils
import { mapButtonByOrderStatus, switchesLabels, mapOrderStatusToColor } from '../../utils/orders-utils'
import { OrderModel } from '../../types/orders'
import { selectMultipleOrders, selectOrder, updateOrderStatus, print } from '../../pages/orders/states/actions'
import { useLongPress } from '../../utils/event-utils'
import { isOrderUpdatingSelector, selectedCardOrdersSelector, switchesSelector } from '../../pages/orders/states/reducer'
import { getLoggedUser } from '../../redux/features/userStateSlice'

type StyledCardProps = {
  isSelected: boolean
}

const StyledCard = styled(Card)<StyledCardProps>(({ theme, isSelected }) => ({
  ...theme.typography.body2,
  marginBottom: theme.spacing(0.75),
  borderStyle: 'solid',
  borderWidth: 1.5,
  padding: 5,
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '30% 70%',
  backgroundColor: isSelected ? alpha(colors.blue['400'], 0.4) : 'white'
}))

type OrdersCardProps = {
  order: OrderModel
}

const OrdersCard = ({ order }: OrdersCardProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedCardOrders = useSelector(selectedCardOrdersSelector)
  const isOrderUpdating = useSelector(isOrderUpdatingSelector)
  const switchesStates = useSelector(switchesSelector)
  const user = getLoggedUser(useSelector)
  const isAdmin = user.isAdmin

  const getCardHeight = React.useCallback(() => {
    if (window.screen.orientation.type === 'landscape-primary') {
      return '10vw'
    } else {
      return '10vh'
    }
  }, [])
  
  const [showOrderDetails, setShowOrderDetails] = React.useState<boolean>(false)
  const [cardHeight, setCardHeight] = React.useState<string | number>(getCardHeight())

  React.useEffect(() => {
    window.matchMedia("(orientation: portrait)").addEventListener('change', () => {
      const height = getCardHeight()
      setCardHeight(height)
    })
  }, [window.screen.orientation.type])

  return (
    <StyledCard
      isSelected={selectedCardOrders[order.orderId]}
      style={{ minHeight: cardHeight, borderColor: mapOrderStatusToColor[order.status] }}
      variant="outlined"
      key={`card-${order.orderId}`}
      onClick={() => {
        if (order.status === 'declined') {
          return dispatch(selectOrder(order))
        }
      }}
      { ...useLongPress(() => dispatch(selectMultipleOrders(order)), 300) }
    >
      <CardContent 
        sx={{
          paddingX: 0,
          paddingY: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'relative',
        }}
        key={`card-content-${order.orderId}`}
        >
        <div
          key={`card-div-${order.orderId}`}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '1vw' }} color="text.secondary" key={`order-id-${order.orderId}`}>
            <strong>{`${t('orders.cards.orderId')}: `}</strong>{order.orderId}
          </Typography>
          <div
            style={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => setShowOrderDetails(!showOrderDetails)}
          >
            {showOrderDetails ? <ExpandLessOutlinedIcon sx={{ width: '1.5vw' }}  /> : <ExpandMoreOutlinedIcon sx={{ width: '1.5vw' }} />}
          </div>
          
        </div>
        {switchesStates.showTotalQuantity && (
          <Typography sx={{ fontSize: '1vw' }} color="text.secondary" key={`total-amount-${order.orderId}`}>
            <strong>{`${t(`orders.cards.totalAmount`)}: `}</strong>
            {order.totalAmount}
          </Typography>
        )}
        {showOrderDetails && order.items.map((item, index) =>
          (
            <div
              key={`card-div-hidden-${index}`}
              style={{
                marginTop: '1vw',
                marginBottom: index === order.items.length - 1 && '1.2vw' || 0,
                padding: 2.5,
              }}
            >
              <Typography sx={{ fontSize: '1vw' }} color="text.secondary" key={`product-${index}`}>
                <strong>{t('orders.cards.product')}: </strong>{item.product}
              </Typography>
              {item.title && <Typography sx={{ fontSize: '1vw' }} color="text.secondary" key={`type-${index}`}>
                <strong>{t('orders.cards.type')}: </strong>{item.title}
              </Typography>}
              <Typography sx={{ fontSize: '1vw' }} color="text.secondary" key={`quantity-${index}`}>
                <strong>{t('orders.cards.amount')}: </strong>{item.quantity}
              </Typography>
              <Typography sx={{ fontSize: '1vw' }} color="text.secondary" key={`date-${index}`}>
                <strong>{t('orders.cards.date')}: </strong>{moment(order.date).format('LLL')}
              </Typography>
              {index !== order.items.length - 1 && (
                <div
                  key={`divider-${index}`}
                  style={{
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomColor: colors.grey['500'],
                    borderBottomWidth: 1,
                    marginTop: '1vw',
                  }}
                />
              )}
            </div>
          )
        )}
      </CardContent>
      <div style={{ justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
        {mapButtonByOrderStatus[order.status].map((button, index, array) => (
          <div
            style={{
              fontSize: '1vw',
              marginBottom: (!index && array.length > 1) && '0.5vw' || 0,
              borderStyle: 'solid',
              borderWidth: 1,
              width: '100%',
              borderRadius: 3,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textTransform: 'uppercase',
              padding: 4,
              color: colors.blue['600'],
              borderColor: colors.blue['600'],
              zIndex: 100,
              ...button.style,
            }}
            key={`button-${index}`}
            onClick={async () => {
              if (!selectedCardOrders[order.orderId]) {
                if (button.text === 'print') {
                  const params = isAdmin ? { ownerId: order.ownerId } : null
                  const totalAmount = order.totalAmount || 0
                  const totalWeight = order.totalWeight || 0
                  if (totalAmount >= 2 && totalWeight >= 5000) {
                    return dispatch(selectOrder(order))
                  }
                  return dispatch(print({ orderId: order.orderId, params }))
                }
                if (!isOrderUpdating) {
                  if (button.action === 'declined') {
                    return dispatch(selectOrder(order))
                  }
                  if (button.action) {
                    dispatch(updateOrderStatus({ order, status: button.action }))
                  }
                }
              }
  
            }}
          >
            {isOrderUpdating
              ? (<CircularProgress size='1.2vw' />) 
              : (<>
                  <div
                    key={`button-label-${index}`}
                    style={{
                      marginRight: button.icon && '1vw' || 0,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    {button.icon}
                  </div>
                  {t(`orders.cards.buttons.${button.text}`)}
                </>
              )
            }
          </div>
        ))}
      </div>
    </StyledCard>
  )
}

export default OrdersCard