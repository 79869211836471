// Dependencies
import { Collapse, List, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranslation } from 'react-i18next';

//Configs
import colorConfigs from '../../configs/colorConfigs';
import { RouteType } from '../../routes/config';
import sizeConfigs from '../../configs/sizeConfigs';
import { selectCurrentPath } from '../../redux/features/appStateSlice';

// Components
import SidebarItem from './SidebarItem';

type Props = {
  item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const currentPath = useSelector(selectCurrentPath)
  const { t } = useTranslation()

  useEffect(() => {
    if (currentPath.includes(item.state)) {
      setOpen(true);
    }
  }, [currentPath, item]);

  return (
    item.sidebarProps ? (
      <>
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{
            // justifyContent: 'space-between',
            '&: hover': {
              backgroundColor: colorConfigs.sidebar.hoverBg
            },
            paddingY: `${sizeConfigs.sidebar.paddingRaw / 2}px`,
            // paddingX: sizeConfigs.sidebar.padding,
            paddingX: `1vw`,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-start' }}>
            {item.sidebarProps.icon}
            <Typography sx={{ fontSize: '1.3vw', marginLeft: '0.5vw' }}>
              {t(item.sidebarProps.textPath)}
            </Typography>
          </div>
          {open ? <ExpandLessOutlinedIcon sx={{ width: '1.8vw' }} /> : <ExpandMoreOutlinedIcon sx={{ width: '1.8vw' }} />}
        </ListItemButton>
        <Collapse in={open} timeout='auto'>
          <List>
            {item.child?.map((route, index) => (
              route.sidebarProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={route.path} />
                ) : (
                  <SidebarItem item={route} key={route.path} />
                )
              ) : null
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SidebarItemCollapse;