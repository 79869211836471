// Dependencies
import moment from 'moment'
import { Columns, Datas, ColumnsDefinition } from 'react-csv-downloader/dist/esm/lib/csv'

// Utils
import { OrderModel, OrderStatusTypes } from '../types/orders'
import { getOrdersByStatus } from './orders-utils'

export type CsvDownloaderPayload = {
    datas: Datas
    columns: Columns
}

export type CsvDataItem = {
    [key: string]: string
}

const columnsIdPrefix = 'cell'

const getColumnId = (index: number): string => `${columnsIdPrefix}${index}`

export const formatCsvColumns = (columnName: string, param: string, useSignal: string = '', data: any[]): ColumnsDefinition =>
    data.map((item, index) => ({
        id: getColumnId(index),
        displayName: `${columnName}${useSignal} ${item[param] || param}`
    }))

export const formatCsvData = (csvData: any[], columnsSize: number, dataIndex: number): Datas => {
    let datas: Datas = []
    for (let i = 0; i < columnsSize; i++) {
        const data: CsvDataItem[] = csvData.map(item => ({
            [getColumnId(i)]: item
        }))
        if (!i) {
            datas.push(...data)
        } else {
            const size = data.length
            datas = datas.map((d: any, dIndex) => {
                if (
                    (dIndex >= (dataIndex * size) && dIndex < size + (dataIndex * size)) &&
                    !!data[dIndex - (dataIndex * size)]    
                ) {
                    d[getColumnId(i)] = data[dIndex - (dataIndex * size)][getColumnId(i)]
                }
                return d
            })
        }
    }
    return datas
}

export const getOrdersCsvData = (orders: OrderModel[], translate: any, status: OrderStatusTypes): CsvDownloaderPayload => {
    const { data: filteredOrders, maxCount } = getOrdersByStatus(orders, status)
    let datas: Datas = []
    const columns: Columns = []

    filteredOrders.forEach((order, index) => {
        const id = `cell${index}`
        columns.push({
            id,
            displayName: `${translate('orders.cards.orderId')}: ${order.orderId}, ${translate('orders.cards.date')}: ${moment(order.date).format('L')}`,
        })

        for (let i = 0; i < maxCount; i++) {
            const itemIndex = i
            const item = order.items[i]
            let data: CsvDataItem[] = []
        
            data = [
                {
                    [id]: item ? `${translate('orders.cards.orderNumber')}: ${item.orderNumber}` : '',
                },
                {
                    [id]: item ? `${translate('orders.cards.product')}: ${item.product}` : '',
                },
                {
                    [id]: item ? `${translate('orders.cards.amount')}: ${item.quantity}` : '',
                },
                {
                    [id]: item ? `${translate('orders.cards.weight')}: ${item?.title || ''}` : '',
                },
                {
                    [id]: item ? `${translate('orders.cards.location')}: ${item.vendor}` : '',
                },
                {
                    [id]: '',
                },
            ]

            if (!index) {
                datas.push(...data)
            } else {
                const size = data.length
                datas = datas.map((d: any, dIndex) => {
                    if (
                        (dIndex >= (itemIndex * size) && dIndex < size + (itemIndex * size)) &&
                        !!data[dIndex - (itemIndex * size)]    
                    ) {
                        d[id] = data[dIndex - (itemIndex * size)][id]
                    }
                    return d
                })
            }
        }
    })
    return {
        datas,
        columns
    }
}