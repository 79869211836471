// Dependencies
import { ofType, combineEpics, StateObservable } from 'redux-observable'
import { catchError, map, mergeMap, finalize } from 'rxjs/operators'
import { from, Observable, of } from 'rxjs'

// States
import { AddProductAction, AddProductActions, AddProductActionsTypes, DeleteImageAction, UploadImageAction } from './types'
import { addProductInitialState, fieldSelector, metafieldsListSelector } from './reducer'
import { addProductError, addProductSuccess, deleteImageError, deleteImageSuccess, setFields, uploadImageError, uploadImageSuccess } from './actions'

// Utils
import { ProductsService } from '../../../../services/products'
import { Product } from '../../../../types/products'
import { mapStateToAddProductApiInput } from '../../../../utils/product-utils'
import { translate } from '../../../../i18n'
import { FilesService } from '../../../../services/files'
import { RootState } from '../../../../redux/store'

const productsService = new ProductsService()
const filesService = new FilesService()

const createProductEpic = (
    action$: Observable<AddProductActions>,
    state$: StateObservable<RootState>
): any =>
    action$.pipe(
        ofType<AddProductActions, AddProductActionsTypes.ADD_PRODUCT, AddProductAction>(AddProductActionsTypes.ADD_PRODUCT),
        mergeMap(({ payload }) => {
            const metafieldsList = metafieldsListSelector(state$.value)
            const data: Partial<Product> = mapStateToAddProductApiInput(payload, metafieldsList)
            return from(productsService.add(data)).pipe(
                map(() => addProductSuccess({ children: translate('products.add.feedback.save.success'), severity: 'success' })),
                catchError(() => of(addProductError({ children: translate('products.add.feedback.save.error'), severity: 'error' }))),
                finalize(() => setFields(addProductInitialState))
            )
        })
    )

const uploadImageEpic = (
    action$: Observable<AddProductActions>,
): any =>
    action$.pipe(
        ofType<AddProductActions, AddProductActionsTypes.UPLOAD_IMAGE, UploadImageAction>(AddProductActionsTypes.UPLOAD_IMAGE),
        mergeMap(({ payload }) =>
            from(filesService.upload(payload)).pipe(
                map(({ data }) => uploadImageSuccess(data)),
                catchError((error) => {
                    console.log(error)
                    return of(uploadImageError(null))
                })
            )
        )
    )

const deleteImageEpic = (
    action$: Observable<AddProductActions>,
    state$: StateObservable<RootState>,
): any =>
    action$.pipe(
        ofType<AddProductActions, AddProductActionsTypes.DELETE_IMAGE, DeleteImageAction>(AddProductActionsTypes.DELETE_IMAGE),
        mergeMap(() => {
            const image = fieldSelector(state$.value, 'image')
            return from(filesService.delete(image)).pipe(
                map(() => deleteImageSuccess()),
                catchError((error) => {
                    console.log(error)
                    return of(deleteImageError(null))
                })
            )
        })
    )

export const addProductEpic: any = combineEpics(
    createProductEpic,
    uploadImageEpic,
    deleteImageEpic,
)