// Utils
import { RootState } from '../../../redux/store'
import { PendingRequestModel, RequestId } from '../../../types/requests'
import { deepCloneObject } from '../../../utils/object-utils'
import { RequestsActions, RequestsActionsTypes } from './types'

export type RequestsState = {
    requests: PendingRequestModel[]
    isLoading: boolean
    snackbar: any
    requestIdUpdating: RequestId | null
}

export const requestsInitialState: RequestsState = {
    requests: [],
    isLoading: false,
    snackbar: null,
    requestIdUpdating: null
}

export const requestsSelector = (state: RootState): PendingRequestModel[] => state.requests.requests

export const isLoadingSelector = (state: RootState): boolean => state.requests.isLoading

export const requestIdUpdatingSelector = (state: RootState): RequestId | null => state.requests.requestIdUpdating

export const snackbarSelector = (state: RootState): any => state.requests.snackbar

export const requestsReducer = (
    state: RequestsState = requestsInitialState,
    action: RequestsActions
): RequestsState => {
    switch (action.type) {
        case (RequestsActionsTypes.GET_ALL_REQUESTS):
            return {
                ...state,
                isLoading: !requestsInitialState.isLoading,
            }
        case (RequestsActionsTypes.GET_ALL_REQUESTS_SUCCESS):
            return {
                ...state,
                requests: action.payload,
                isLoading: requestsInitialState.isLoading,
            }
        case (RequestsActionsTypes.GET_ALL_REQUESTS_ERROR):
            return {
                ...state,
                snackbar: action.payload,
                requests: requestsInitialState.requests,
                isLoading: requestsInitialState.isLoading,
            }
        case (RequestsActionsTypes.UPDATE):
            return {
                ...state,
                requestIdUpdating: action.payload.id,
            }
        case (RequestsActionsTypes.UPDATE_SUCCESS): {
            const { id } = action.payload.data
            const { requests } = state
            const clonedRequests: PendingRequestModel[] = deepCloneObject(requests)
                .filter((item: PendingRequestModel) => item.id !== id)

            return {
                ...state,
                requestIdUpdating: requestsInitialState.requestIdUpdating,
                snackbar: action.payload.snackbar,
                requests: clonedRequests,
            }
        }
        case (RequestsActionsTypes.UPDATE_ERROR):
            return {
                ...state,
                requestIdUpdating: requestsInitialState.requestIdUpdating,
                snackbar: action.payload,
            }
        case (RequestsActionsTypes.CLEAR_FEEDBACK_MESSAGE):
            return {
                ...state,
                snackbar: requestsInitialState.snackbar,
            }
        default:
            return state
    }
}