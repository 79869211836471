import { RootState } from '../../../../redux/store'
import { ManageProductsActions, ManageProductsActionsTypes } from './types'
import { Product, ProductDeletionTranslatedOptions, ProductModel } from '../../../../types/products'
import { deepCloneObject } from '../../../../utils/object-utils'

export interface ManageProductsState {
    rows: ProductModel[]
    selectedRow: Partial<ProductModel>
    isLoading: boolean
    snackbar: any
    products: Product[],
    isRowUpdating: boolean,
    deleteProductReason: ProductDeletionTranslatedOptions | string
}

export const manageProductsInitialState: ManageProductsState = {
    rows: [],
    selectedRow: {},
    isLoading: false,
    snackbar: null,
    products: [],
    isRowUpdating: false,
    deleteProductReason: '',
}

export const selectRowSelector = (state: RootState): Partial<ProductModel> => state.manageProducts.selectedRow

export const rowsSelector = (state: RootState): ProductModel[] => state.manageProducts.rows

export const isLoadingSelector = (state: RootState): boolean => state.manageProducts.isLoading

export const snackbarSelector = (state: RootState): any => state.manageProducts.snackbar

export const productsSelector = (state: RootState): Product[] => state.manageProducts.products

export const isRowUpdatingSelector = (state: RootState): boolean => state.manageProducts.isRowUpdating

export const deleteProductReasonSelector = (state: RootState): ProductDeletionTranslatedOptions | string => state.manageProducts.deleteProductReason
 
export const manageProductsReducer = (
    state: ManageProductsState = manageProductsInitialState,
    action: ManageProductsActions,
): ManageProductsState => {
    switch (action.type) {
        case ManageProductsActionsTypes.SELECT_ROW:
            return {
                ...state,
                selectedRow: action.payload,
            }
        case ManageProductsActionsTypes.DELETE:
            return {
                ...state,
                isRowUpdating: !manageProductsInitialState.isRowUpdating,
            }
        case ManageProductsActionsTypes.DELETE_SUCCESS:
            return {
                ...state,
                selectedRow: manageProductsInitialState.selectedRow,
                isRowUpdating: manageProductsInitialState.isRowUpdating,
                products: action.payload.products,
                rows: action.payload.rows,
                snackbar: action.payload.snackbar,
            }
        case ManageProductsActionsTypes.DELETE_ERROR:
            return {
                ...state,
                selectedRow: manageProductsInitialState.selectedRow,
                isRowUpdating: manageProductsInitialState.isRowUpdating,
                snackbar: action.payload,
            }
        case ManageProductsActionsTypes.GET_ALL:
            return {
                ...state,
                isLoading: action.payload.useLoading ? !manageProductsInitialState.isLoading : false,
            }
        case ManageProductsActionsTypes.GET_ALL_SUCCESS: {
            let productsData = deepCloneObject(state.products)
            let rowsData = deepCloneObject(state.rows)
            
            if (productsData.length !== action.payload.products.length) {
                productsData = action.payload.products
            }

            if (rowsData.length !== action.payload.rows.length) {
                rowsData = action.payload.rows
            }

            return {
                ...state,
                isLoading: manageProductsInitialState.isLoading,
                products: productsData,
                rows: rowsData,
            }
        }

        case ManageProductsActionsTypes.GET_ALL_ERROR:
            return {
                ...state,
                snackbar: action.payload,
                isLoading: manageProductsInitialState.isLoading,
            }
        case ManageProductsActionsTypes.UPDATE_INVENTORY:
            return {
                ...state,
                isRowUpdating: !manageProductsInitialState.isRowUpdating,
            }
        case ManageProductsActionsTypes.UPDATE_INVENTORY_SUCCESS:
            return {
                ...state,
                isRowUpdating: manageProductsInitialState.isRowUpdating,
                rows: action.payload.rows,
                products: action.payload.products,
                snackbar: action.payload.snackbar,
            }
        case ManageProductsActionsTypes.UPDATE_INVENTORY_ERROR:
            return {
                ...state,
                isRowUpdating: manageProductsInitialState.isRowUpdating,
                snackbar: action.payload,
            }
        case ManageProductsActionsTypes.CLEAR_FEEDBACK_MESSAGE: {
            return {
                ...state,
                snackbar: action.payload,
            }
        }
        case ManageProductsActionsTypes.DELETE_REASON: {
            return {
                ...state,
                deleteProductReason: action.payload,
            }
        }
        default:
            return state
    }

}