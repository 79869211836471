// Dependencies
import { ofType, combineEpics, StateObservable } from 'redux-observable'
import { catchError, map, mergeMap, finalize, delay } from 'rxjs/operators'
import { from, Observable, of } from 'rxjs'

// States
import { DeleteAction, GetAllAction, ManageProductsActions, ManageProductsActionsTypes,  UpdateAction } from './types'
import  { updateProductError, updateProductSuccess, getAllProductsSuccess, getAllProductsError, clearFeedbackMessage, deleteProductSuccess, deleteProductError } from './actions'
import { productsSelector, rowsSelector, deleteProductReasonSelector } from './reducer'

// Utils
import { ProductsService } from '../../../../services/products'
import { translate } from '../../../../i18n'
import { findProductAndPosition, mapRowModel } from '../../../../utils/product-utils'
import { RootState } from '../../../../redux/store'

// Types
import { Product, ProductModel, SelectFieldTypes, UpdatableProductParams } from '../../../../types/products'
import { deepCloneObject } from '../../../../utils/object-utils'

const productsService = new ProductsService()

const updateProductEpic = (
    action$: Observable<ManageProductsActions>,
    state$: StateObservable<RootState>,
): any =>
    action$.pipe(
        ofType<ManageProductsActions, ManageProductsActionsTypes.UPDATE_INVENTORY, UpdateAction>(ManageProductsActionsTypes.UPDATE_INVENTORY),
        mergeMap(({ payload: { row, value } }) => {
            const error = { children: translate('products.manage.feedback.save.error'), severity: 'error' }
            const products: Product[] = deepCloneObject(productsSelector(state$.value))

            const { product, positions } = findProductAndPosition(products, row.id, row.variantId, error)

            let field: UpdatableProductParams = (row.selectedField as SelectFieldTypes) === 'inventory' ? 'available' : 'price'

            return from(productsService.update({ [field]: value, variantId: row.variantId }, row.id)).pipe(
                map(({ data }) => {
                    const newData = { ...data, pendingApprovals: data?.pendingApprovals?.length ? data.pendingApprovals : product.pendingApprovals }
                    const rows: ProductModel[] = deepCloneObject(rowsSelector(state$.value))
                    positions.forEach(position => {
                        const updatedProd = {
                            ...newData,
                            variant: {
                                ...products[position].variant,
                                inventory_quantity: newData.variant.inventory_quantity,
                                old_inventory_quantity: newData.variant.old_inventory_quantity
                            }}
                        products[position] = updatedProd
                        rows[position] = mapRowModel(updatedProd)
                    })
                    const snackbar = { children: translate('products.manage.feedback.save.success'), severity: 'success' }
                    return updateProductSuccess({ rows, products, snackbar })
                }),
                catchError((err) => {
                    console.log(err)
                    return of(
                        updateProductError(error)
                    )}
                ),
                finalize(() => clearFeedbackMessage(null))
            )
        }),
    )

const getAllProductsEpic = (
    actions$: Observable<ManageProductsActions>,
): any =>
    actions$.pipe(
        ofType<ManageProductsActions, ManageProductsActionsTypes.GET_ALL, GetAllAction>(ManageProductsActionsTypes.GET_ALL),
        mergeMap(() =>
            from(productsService.getAll()).pipe(
                // delay(3000),
                map(({ data: products }) => {
                    const rows = products.map(mapRowModel)
                    return getAllProductsSuccess({ products, rows })
                }),
                catchError((error) => {
                    console.log(error)
                    return of(
                        getAllProductsError({ children: translate('general.apiError'), severity: 'error' })
                    )}
                ),
                finalize(() => clearFeedbackMessage(null))
            )
        )
    )

const deleteProductEpic = (
    actions$: Observable<ManageProductsActions>,
    state$: StateObservable<RootState>,
): any =>
    actions$.pipe(
        ofType<ManageProductsActions, ManageProductsActionsTypes.DELETE, DeleteAction>(ManageProductsActionsTypes.DELETE),
        mergeMap(({ payload: row  }) => {
            const error = { children: translate('products.manage.feedback.remove.error'), severity: 'error' }
            const reason = deleteProductReasonSelector(state$.value)
            return from(productsService.deleteProduct({ reason, variantId: row.variantId }, row.id)).pipe(
                map(({ data }) => {
                    const products: Product[] = deepCloneObject(productsSelector(state$.value))
                    const rows: ProductModel[] = deepCloneObject(rowsSelector(state$.value))

                    const { product, positions } = findProductAndPosition(products, row.id, row.variantId, error)

                    product?.pendingApprovals.push(data)

                    positions.forEach(position => {
                        products[position] = product
                        rows[position] = mapRowModel(product)
                    })

                    const snackbar = { children: translate('products.manage.feedback.remove.success'), severity: 'success' }
                    return deleteProductSuccess({ products, rows, snackbar })
                }),
                catchError((err) => {
                    console.log(err)
                    return of(
                        deleteProductError(error)
                    )
                }),
                finalize(() => clearFeedbackMessage(null))
            )
        })
    )

export const manageProductsEpic: any = combineEpics(
    updateProductEpic,
    getAllProductsEpic,
    deleteProductEpic,
)